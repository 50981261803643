import { Injectable } from '@angular/core';
import { AmountCalculateService } from 'src/app/public-website/buyer/services/amoun-calculate.service';
import { GetCalculatesParamsService } from 'src/app/public-website/buyer/services/get-calculates-params.service';
import { ViewProductInfoHandlerService } from 'src/app/public-website/buyer/services/view-product-info-handler.service';
import paymentOptionsConstant from 'src/app/shared/paymentOptionsConstant';

@Injectable({
  providedIn: 'root',
})
export class AppleVipsCalculatorService {
  private calculatesParamsService: GetCalculatesParamsService;
  private applePayName: string;
  public dataIsReady: boolean;

  constructor(private amountCalculateService: AmountCalculateService) {
    this.applePayName =
      paymentOptionsConstant.constantPaymentOptions.value.APPLE_PAY;
    this.dataIsReady = false;
  }
  /**
   * Deep clone params and save it into AppleVipsCalculatorService wrapper
   * @param vpihs
   * @param calculatesParamsService
   * @returns AppleVipsCalculatorService
   */
  setSetUpService(
    vpihs: ViewProductInfoHandlerService,
    calculatesParamsService: GetCalculatesParamsService
  ) {
    this.calculatesParamsService = calculatesParamsService.clone({
      vpihs: vpihs.clone(),
      calculateService: this.amountCalculateService,
    });
    return this;
  }

  validateSetUp() {
    if (!this.calculatesParamsService || !this.calculatesParamsService.vpihs)
      throw new Error('Must call setSetUpService first.');
  }

  getVpihs(): ViewProductInfoHandlerService {
    this.validateSetUp();
    return this.calculatesParamsService.vpihs;
  }

  getCalculatesParamsService(): GetCalculatesParamsService {
    this.validateSetUp();
    return this.calculatesParamsService;
  }
  /**
   * this will simulate the original user behaver with selecting any payment method
   * @returns
   */
  async calcVpihsForApple() {
    this.validateSetUp();

    await this.calculatesParamsService.setPaymentOption(this.applePayName);
    this.dataIsReady = true;

    return this.calculatesParamsService.vpihs.getSubmitObject();
  }

  getSubmitObject() {
    if (!this.dataIsReady)
      throw new Error(
        'AppleVipsCalculatorService is not ready please call calcVpihsForApple() first.'
      );
    return {...this.calculatesParamsService.vpihs.getSubmitObject(), promoCodeName: this.getCodeName()};
  }

  setPromoCode(promoCode: { id: string; type: string; precentage: number, codeName: string }, discountAmount: number) {
    this.calculatesParamsService.setCodeName(promoCode.codeName);
    this.calculatesParamsService.vpihs.PromocodeId = promoCode.id;
    this.calculatesParamsService.vpihs.discount = discountAmount;
    if (
      (promoCode.precentage == 100 && promoCode.type == 'percentage') ||
      (promoCode.type == 'flat' && this.calculatesParamsService.vpihs.total == 0)
    ) {
      this.dataIsReady = false;
    }
  }

  disablePromoCode() {
    this.calculatesParamsService.vpihs.PromocodeId = null;
    this.calculatesParamsService.vpihs.applyPromoCode(0);
    this.dataIsReady = true;
  }

  getCodeName(){
    return this.calculatesParamsService.params.codeName;
  }
}
