import { environment } from "src/environments/environment";
class ApplePaymentConfig {
  appleJsVersion: number = 3;

  country = {
    countryCode: 'EG',
    currencyCode: 'EGP',
  };

  supportedNetworks = ['visa', 'masterCard'];
  merchantCapabilities = ['supports3DS'];
  merchantIdentifier: string;


  paymentOption: PaymentOptions = {
    "requestPayerName": false,
    "requestPayerEmail": false,
    "requestPayerPhone": false,
    "requestShipping": false,
    "shippingType": "shipping"
  }

  constructor(){
    this.merchantIdentifier = environment.apple_pay_merchant_id;
    if(!this.merchantIdentifier) throw new Error("environment.apple_pay_merchant_id is missing")
  }

  getMerchantId(){
    return this.merchantIdentifier;
  }

  // Payment Request API
  getPaymentRequestAPIData(amount: string){
    const paymentMethod = {
      supportedMethods: 'https://apple.com/apple-pay',
      data: {
        version: 3,
        merchantIdentifier: this.merchantIdentifier,
        merchantCapabilities: this.merchantCapabilities,
        supportedNetworks: this.supportedNetworks,
        countryCode: this.country.countryCode,
      },
    };

    const paymentDetails = {
      total: {
        label: 'Easykash',
        amount: {
          value: amount,
          currency: this.country.currencyCode,
        },
      },
    };

    return {paymentMethod, paymentDetails, paymentOption: this.paymentOption}
  }

  // Apple js api
  getRequest(paymentData: VIPS, merchantName: string = "Easykash"){
    const data = {
      countryCode: this.country.countryCode,
      currencyCode: this.country.currencyCode,
      supportedNetworks: this.supportedNetworks,
      merchantCapabilities: this.merchantCapabilities,
      lineItems: [
        { label: 'Subtotal', amount: paymentData.subTotal },
        { label: 'Taxes', amount: paymentData.taxes },
        {
          label: `Discount (${paymentData.promoCodeName || 'None'})`,
          amount: `${paymentData.discount}`,
        },
        { label: 'Fees', amount: paymentData.fees },
      ],
      total: { label: merchantName, amount: paymentData.total },
    };
    if(paymentData.promoCodeName){
      data["supportsCouponCode"] = false;
      data["couponCode"] = paymentData.promoCodeName;
    }

    return data;
  }
}

export const applePaymentConfig = new ApplePaymentConfig();


type VIPS = {
    subTotal: number;
    discount: number;
    fees: number;
    taxes: number;
    total: number;
    vat: number;
    roundUpAmount: number;
    quantity: number;
    redirectUrl: string;
    paymentOptions: string;
    merchantFees: number;
    mFees: number;
    mAddFees: boolean;
    promoCodeName: string;
}
