import { Router } from '@angular/router';
import { GlobalLoaderService } from './../../../services/global-loader.service';
import { CreditCardPaymentService } from './../../../../public-website/buyer/services/creditCardPayment.service';
import { Subscription } from 'rxjs';
import { Component, OnInit, OnDestroy, Input } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { applePaymentConfig } from './helper/ApplePaymentConfig';
import { AppleMerchantValidation } from './services/apple-merchant-validation.service';
import { SubmitBuyerInfoService } from 'src/app/public-website/buyer/services/submit-buyer-info.service';
import paymentOptionsConstant from 'src/app/shared/paymentOptionsConstant';
import { ViewProductInfoHandlerService } from 'src/app/public-website/buyer/services/view-product-info-handler.service';
import { environment } from './../../../../../environments/environment';
import { FetchPAymentGroupForSeller } from 'src/app/shared/services/fetch-payment-groups-for-seller.service';
import { AppleVipsCalculatorService } from './services/apple-vips-calculator.service';

@Component({
  selector: 'apple-pay-payment-option',
  templateUrl: './apple-pay.component.html',
  styleUrls: ['./apple-pay.component.scss'],
})
export class ApplePayComponent implements OnInit, OnDestroy {
  lang: { value: 'en-US' | 'ar-AB'; subscription: Subscription } = {
    value: 'en-US',
    subscription: null,
  };
  isAvailable = false;
  applePayName = paymentOptionsConstant.constantPaymentOptions.value.APPLE_PAY;
  isProduction = environment.production;

  productAllowedOptions: Subscription;
  @Input() submitBuyerDataFunction: () => Promise<void>;
  @Input() validateBuyerInputs: () => boolean;

  constructor(
    private translate: TranslateService,
    private appleMerchantValidation: AppleMerchantValidation,
    private creditCardService: CreditCardPaymentService,
    private submitBuyerInfo: SubmitBuyerInfoService,
    private appleVipsCalculatorService: AppleVipsCalculatorService,
    private fetchPaymentGroups: FetchPAymentGroupForSeller,
    private vpihs: ViewProductInfoHandlerService,
    private globalLoaderService: GlobalLoaderService,
    private router: Router
  ) {}
  ngOnDestroy(): void {
    if (this.lang.subscription) {
      this.lang.subscription.unsubscribe();
    }

    if (this.productAllowedOptions) {
      this.productAllowedOptions.unsubscribe();
    }

    this.globalLoaderService.enable();
  }

  async ngOnInit() {
    this.handleLanguage();
    await this.checkAvailability();
  }

  private handleLanguage() {
    this.translate.use(localStorage.getItem('language'));
    this.lang.subscription = this.translate.onLangChange.subscribe((lang) => {
      console.log('apple-pay: changing lang to ', lang);
      if (lang.lang) this.lang.value = lang.lang === 'ar' ? 'ar-AB' : 'en-US';
    });
  }

  private async checkAppleCapabilities(): Promise<boolean>{
    try {
      const capabilities = await window['ApplePaySession']?.applePayCapabilities(
        applePaymentConfig.getMerchantId()
      );
      console.log("---------")
      console.log("applePaymentConfig.getMerchantId() = ",  applePaymentConfig.getMerchantId())
      console.log("capabilities.paymentCredentialStatus ", capabilities.paymentCredentialStatus)
      console.log("capabilities.paymentCredentialStatus ", capabilities)
      console.log("---------")
      switch (capabilities.paymentCredentialStatus) {
        case 'paymentCredentialsAvailable':
        case 'paymentCredentialStatusUnknown':
        case 'paymentCredentialsUnavailable':
          return true;
        default:
          return false
      }
    } catch (e) {
      console.log('Apple pay is not available, ', e);
      return false
    }
  }

  private async checkAvailability() {
    this.productAllowedOptions =
      this.fetchPaymentGroups.productAllowedOptions.subscribe(
        async (data: { name: string; nameAr }[]) => {
          const applePaySession = window['ApplePaySession'];
          const productHasApplePay = data.find(
            (p: { name: string; nameAr }) => p?.name === this.applePayName
          );

          if (!productHasApplePay || !applePaySession) {
            this.isAvailable = false;
            return;
          }

          this.isAvailable = await this.checkAppleCapabilities()
        }
      );
  }

  onValidateMerchant(session: any){
    return async (event) => {
      console.log('On validate merchant,');
      let validationResponse = {};
      try {
        await this.submitBuyerDataFunction();
        const response = this.submitBuyerInfo.data;
        console.log("submitBuyerInfo response -> ", response)
        if (!response) throw new Error('No data available to process..');

        this.appleMerchantValidation.deactivateLoader();
        this.appleMerchantValidation.setParams({
          validationURL: event.validationURL,
          trxId: response.PaymentAttempt.id,
          trxRefNum: this.creditCardService.trxRefNum,
        });
        validationResponse = await this.appleMerchantValidation.make();
        if(!validationResponse) throw new Error('Empty Merchant validation Response');
        session.completeMerchantValidation(validationResponse);
      } catch (e) {
        console.log('Something went wrong with Merchant validation,', e);
      } finally {}
    }
  }

  onPaymentAuthorized(session: any){
    return  async (event) => {
      console.log('Payment Authorization, Event = ', event);
      const token = event.payment.token;
      let trx = this.creditCardService.trxRefNum;
      if (!token.paymentData) return session.completePayment(1);
      const tokenString = JSON.stringify(token.paymentData);
      console.log("------------- paymentData ------------")
      console.log(JSON.stringify(token.paymentData))
      console.log("---------------------------------")
      try {
        this.creditCardService.deactivateLoader()
        this.creditCardService.setPayToken({paymentToken: tokenString, trxRef: trx});
        const creditCardService = await this.creditCardService.make();
        if(creditCardService?.message !== "APPROVED") throw new Error(creditCardService?.message ?? "Something wrong happened");
        session.completePayment(window["ApplePaySession"].STATUS_SUCCESS);
      } catch (e) {
        console.log('Payment Authorization Failed, ', e);
        session.completePayment(window["ApplePaySession"].STATUS_FAILURE ?? 1);
      } finally {
        setTimeout(() => {
          return this.router.navigate(['gate'], {
            queryParams: {
              refNum: trx,
            },
          });
        }, 1000);
      }
    };
  }

  async onApplePayButtonClicked() {
    try {
      const vips = this.appleVipsCalculatorService.getSubmitObject();
      console.log("vips, ", vips);
      console.log("this.appleVipsCalculatorService.getCodeName() ", this.appleVipsCalculatorService.getCodeName());
      // pass the payment selection validation
      this.vpihs.setPaymentOption(this.applePayName);
      // runs validation for info submitter
      if(!this.validateBuyerInputs()) throw new Error("Form Data is missing, please fill all fields.");
      this.globalLoaderService.disable();

      //@ts-ignore
      var session = new window.ApplePaySession(
        applePaymentConfig.appleJsVersion,
        applePaymentConfig.getRequest(vips)
      );

      session.onvalidatemerchant = this.onValidateMerchant(session);
      session.onpaymentauthorized = this.onPaymentAuthorized(session);


      session.oncancel = () => {
        console.log('oncancel');
        this.globalLoaderService.enable();
      };

      session.abort = () => {
        console.log('abort');
        this.globalLoaderService.enable();
      };

      session.begin();
    } catch (e) {
      console.log('onApplePayButtonClicked: error ', e);
    }
  }
}
