import { Injectable } from '@angular/core';
import constantURL from 'src/app/shared/constantURL';
import { BasePostService } from 'src/app/shared/services/basePostService.service';

@Injectable({
  providedIn: 'root',
})
export class AppleMerchantValidation extends BasePostService {
    _path = constantURL.Apple.validateMerchant;
}
